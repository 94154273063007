.slider {

    .slide {
        transition: transform 0.5s cubic-bezier(0.6, 0.46, 0.42, 1), opacity 0.5s cubic-bezier(0.6, 0.46, 0.42, 1), scale 0.5s cubic-bezier(0.6, 0.46, 0.42, 1);
        transform: translate(0px, 0px);
        position: absolute;
        scale: 0.6;

        &.right {
            scale: 0.5;
            transform: translate(900px, -85px);
        }
    
        &.left {
            scale: 0.5;
            transform: translate(-900px, -85px);
        }
    }

    .circle {
        position: absolute;
        width: 1100px;
        top: 220px;
        z-index: 2;
        pointer-events: none;

        -webkit-mask-image: url(/../public/images/pages/juise-raw/circle-mask.svg);
        mask-image: url(/../public/images/pages/juise-raw/circle-mask.svg);

        &.slide-is-changing {
            -webkit-mask-image: none;
            mask-image: none;
        }
    }
}