.slide {
    $bezier-excess: cubic-bezier(.26,-0.03,.17,1.43);
    $paint-transition: scale 0.4s cubic-bezier(0.6, 0.46, 0.42, 1) .0s, opacity 0.6s cubic-bezier(0.6, 0.46, 0.42, 1) .0s;

    // transition: transform 0.5s cubic-bezier(0.6, 0.46, 0.42, 1), opacity 0.5s cubic-bezier(0.6, 0.46, 0.42, 1), scale 0.5s cubic-bezier(0.6, 0.46, 0.42, 1);
    // transform: translate(0px, 0px);
    
    .paint-yellow-right {
        transform-origin: bottom left;
        transition: $paint-transition;
        scale: 0.2;
        right: -40px;
        opacity: 0;

        &.show-background {
            opacity: 1;
            scale: 1;
        }
    }
    
    .paint-yellow-left {
        transform-origin: bottom right;
        transition: $paint-transition;
        scale: 0.2;
        left: -100px;
        opacity: 0;

        &.show-background {
            opacity: 1;
            scale: 1;
        }
    }
    
    .leaf-one {
        left: 30px;
        top: 35px;
    }

    .leaf-two {
        left: 90px;
        top: 60px;
    }
    
    .cut-mango {
        right: 40px;
        bottom: 50px;
    }

    .bottle {
        opacity: 0;
        scale: 0.8;
        -webkit-filter: drop-shadow(5px 5px 5px #222);
        filter: drop-shadow(30px 32px 46px rgba(61,20,12,0.9));

        &.show-bottle {
            opacity: 1;
            scale: 1;
        }
    }

    .slice-1 {
        left: 50px;
        bottom: 140px;
        filter: drop-shadow(30px 32px 20px rgba(109, 27, 11, 0.6));
    }

    .slice-2 {
        left: 45px;
        bottom: 200px;
        scale: 0.8 1;
        filter: drop-shadow(30px 32px 20px rgba(109, 27, 11, 0.6));
    }

    .slice-3 {
        left: 80px;
        top: 40px;
        scale: 0.6 1;
        filter: drop-shadow(30px 32px 20px rgba(109, 27, 11, 0.6));
    }

    .raw-mango-filled {
        top: 100px;
    }

    .raw-mango-empty {
        top: 100px;
    }

    .slide-down {
        transition: transform 0.5s $bezier-excess var(--delay), opacity 0.5s $bezier-excess var(--delay);
        transform: translateY(-50px);
        opacity: 0;
        
        &.show-background {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .slide-up {
        transition: transform 0.5s cubic-bezier(.26,-0.03,.17,1.43) var(--delay), opacity 0.5s cubic-bezier(.26,-0.03,.17,1.43) var(--delay);
        transform: translateY(50px);
        opacity: 0;
        
        &.show-background {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .zoom-out {
        transition: transform 0.5s cubic-bezier(0.6, 0.46, 0.42, 1) var(--delay), opacity 0.5s cubic-bezier(0.6, 0.46, 0.42, 1) var(--delay), scale 0.5s cubic-bezier(0.6, 0.46, 0.42, 1) var(--delay);
        transform: translateY(50px);
        opacity: 0;
        scale: 0.8;
        
        &.show-background {
            transform: translateY(0);
            opacity: 1;
            scale: 1;
        }
    }

    .rotate-in-to-left {
        transform-origin: bottom right;
        transition: opacity 0.4s cubic-bezier(0.6, 0.46, 0.42, 1) var(--delay), rotate 0.5s $bezier-excess var(--delay);
        rotate: 45deg;
        opacity: 0;

        &.show-background {
            rotate: var(--rotation);
            opacity: 1;
        }
    }

    // .show-background.is-unselected {
    //     transition: opacity 0.4s cubic-bezier(0.6, 0.46, 0.42, 1), transform 0.4s cubic-bezier(0.6, 0.46, 0.42, 1);
    //     opacity: 0;
    //     transform: translateY(40px);
    // }
}
