.mango-site {

    .bg {
        transition: background-color 0.5s cubic-bezier(0.6, 0.46, 0.42, 1);
        background: rgb(220,133,30);
        background: linear-gradient(138deg, rgba(220,133,30,1) 36%, rgba(255,234,97,1) 100%);
        transition: opacity 0.5s cubic-bezier(0.6, 0.46, 0.42, 1);
        
        &.red {
            opacity: 0;
            background: rgb(216,33,94);
            background: linear-gradient(138deg, rgba(216,33,94,1) 36%, rgba(255,120,102,1) 100%);
            
            &.show {
                opacity: 1;
            }
        }
        
        &.brown {
            opacity: 0;
            background: rgb(245,96,15,1);
            background: linear-gradient(138deg, rgba(245,96,15,1) 36%, rgba(255,226,102,1) 100%);
            
            &.show {
                opacity: 1;
            }
        }
    }
}