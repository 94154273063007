@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.page-loader {
		/* @apply flex  */
		transition: transform 0.5s cubic-bezier(.6,.46,.42,1) 0.05s, border-radius 0.8s cubic-bezier(.6,.46,.42,1);
		border-radius: 0px 0px 100% 100% / 0px 0px 25% 25%;
		transform: translateY(-100vh);
		z-index: 999;
	}

	.page-loader.loading {
		transform: translateY(0);
		border-radius: 0 0 0 0 / 0 0 0 0;
		/* border-radius: 0px 0px 100% 100% / 0px 0px 25% 25%; */
	}

	.welcome-text {
		opacity: 1;
		transform: translateY(0);
		transition: opacity 0.3s cubic-bezier(.6,.46,.42,1) 0.1s, transform 0.5s cubic-bezier(.6,.46,.42,1);
		z-index: 999 !important;
	}
	
	.welcome-text.hide {
		opacity: 0;
		transform: translateY(-300px);
	}

	.text-animate {
		transition: opacity 0.5s cubic-bezier(.6,.46,.42,1) 0.3s, transform 0.3s cubic-bezier(.6,.46,.42,1) 0.3s;
		transform: translateY(40px);
		opacity: 0;
	}
	
	.text-animate.show {
		transform: translateY(0px);
		opacity: 1;
	}

	.image-zoom img {
		transition: opacity 0.5s cubic-bezier(.6,.46,.42,1) 0.3s, scale 0.3s cubic-bezier(.6,.46,.42,1) 0.3s;
		scale: 1.2;
		opacity: 0;
	}

	.image-zoom.show img {
		scale: 1;
		opacity: 1;
	}

	.contact-me-modal {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgb(14 15 16 / 77%);
		display: flex;
		justify-content: center;
		align-items: center;
		backdrop-filter: blur(6px);
		opacity: 0;
		transition: opacity 0.2s cubic-bezier(.6,.46,.42,1);
			
	}

	.contact-me-modal .container {
		background-color: black !important;
		display: flex;
		flex-direction: column;
		color: white;
		transform: translateY(20px);
		transition: transform 0.3s cubic-bezier(.6,.46,.42,1) 0.1s, opacity 0.3s cubic-bezier(.6,.46,.42,1) 0.1s;
		opacity: 0;
	}

	.contact-me-modal.show {
		opacity: 1;
	}

	.contact-me-modal.show .container {
		transform: translateY(0);
		opacity: 1;
	}
}

@media (max-width: 1023px) {
	.no-scrollbar-mobile {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	.no-scrollbar-mobile::-webkit-scrollbar {
		display: none;
	}

	body::-webkit-scrollbar {
		display: none;
	};
	
	body {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
}

body {
	color: white;
	margin: 0;
	/* overflow: hidden; */
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: black;
}

.loader {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: relative;
	animation: rotate 1s linear infinite;
	margin-right: 8px;
  }
  .loader::before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	inset: 0px;
	border-radius: 50%;
	border: 2px solid #FFF;
	animation: prixClipFix 2s linear infinite ;
  }

  @keyframes rotate {
	100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
	  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
	  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
	  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
	  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
	  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
